<template>
  <div class="pt-5">
    <modal-loading :is-loading="loading" message="メール送信中..." />
    <v-row justify="center">
      <div class="col-5">
        <h2 class="mb-10">パスワードをお忘れの方</h2>
        <div class="mb-7">
          <div>
            パスワードをリセットするためには、メールアドレスを入力してください。
            新しいパスワードの設定をするURLを送信します。
          </div>
          <div class="px-3">
            <v-row justify="end">
              <router-link :to="{ name: 'login' }">
                ログインページに戻る
              </router-link>
            </v-row>
          </div>
        </div>

        <form @keyup.enter="handleSendEmail">
          <v-alert v-if="sendSuccessMessage" text class="mb-8" color="success">
            {{ sendSuccessMessage }}
          </v-alert>
          <div>
            <v-text-field
              v-model="formData.email"
              type="text"
              label="メールアドレス"
              :error-messages="emailError"
              outlined
            />
          </div>
          <div class="px-3">
            <v-row justify="end">
              <v-btn color="primary" large @click="handleSendEmail">
                送信
              </v-btn>
            </v-row>
          </div>
        </form>
      </div>
    </v-row>
  </div>
</template>

<script>
import ModalLoading from "../../components/ModalLoading.vue";
import { createData } from "../../axios";

export default {
  name: "SendEmail",

  components: {
    ModalLoading,
  },

  data() {
    return {
      loading: false,
      formData: {
        email: "",
      },
      emailError: "",
      sendSuccessMessage: "",
    };
  },

  methods: {
    handleSendEmail() {
      this.loading = true;
      this.emailError = "";
      this.sendSuccessMessage = "";
      if (this.formData.email === "") {
        this.emailError = "メールアドレスを入力してください。";
        this.loading = false;
        return;
      }
      createData("password/email", this.formData)
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.sendSuccessMessage = `「${this.formData.email}」に新しいパスワードの設定をするURLを送信しました。`;
          } else {
            this.loading = false;
            this.emailError = `「${this.formData.email}」にメールを送信できませんでした。`;
          }
        })
        .catch(() => {
          this.loading = false;
          this.emailError = `「${this.formData.email}」にメールを送信できませんでした。`;
        });
    },
  },
};
</script>
